<template>
    <v-container>
        <h2 class="primary--text mb-1" style="font-weight:400;">Safety</h2>
        <v-row>
            <v-col cols="12" >
                <v-card outlined elevation="2" @click="openIncident" style="padding: 10px;">
                    <p style="color: #002060; font-size:18px;margin-bottom: 0px;">1. Safety Incident</p>
                </v-card>
            </v-col>
            <v-col cols="12" >
                <v-card outlined elevation="2" @click="openSafety" style="padding: 10px;">
                    <p style="color: #002060; font-size:18px;margin-bottom: 0px;">2. Safety Observations</p>
                </v-card>
            </v-col>
            <v-col cols="12" >
                <v-card outlined elevation="2" @click="openAudit" style="padding: 10px;">
                    <p style="color: #002060; font-size:18px;margin-bottom: 0px;">3. Audit & Inspections</p>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
export default {
    name: "Safety",
    data: () => ({
        user_p : JSON.parse(localStorage.getItem('user'))
    }),
    methods:{
        openIncident() {
            this.$router.push('/incident')
        },
        openSafety() {
            this.$router.push('/addsafety')
        },
        openAudit() {
            this.$router.push('/audit/overview')
        }
    }
}
</script>